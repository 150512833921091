svg
{
    touch-action: none;
}

.jvectormap-container
{
    height: 100%;
    overflow: hidden;
    position: relative;
    touch-action: none;
    width: 100%;
}

.jvectormap-tip
{
    background: #292929;
    border: solid 1px #CDCDCD;
    border-radius: 3px;
    color: white;
    display: none;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
    position: absolute;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback
{
    background: #292929;
    border-radius: 3px;
    box-sizing: content-box;
    color: white;
    cursor: pointer;
    left: 10px;
    line-height: 10px;
    padding: 3px;
    position: absolute;
    text-align: center;
}

.jvectormap-zoomin,
.jvectormap-zoomout
{
    height: 10px;
    width: 10px;
}

.jvectormap-zoomin
{
    top: 10px;
}

.jvectormap-zoomout
{
    top: 30px;
}

.jvectormap-goback
{
    bottom: 10px;
    padding: 6px;
    z-index: 1000;
}

.jvectormap-legend-title
{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.jvectormap-legend-cnt
{
    position: absolute;
}

.jvectormap-legend-cnt-h
{
    bottom: 0;
    right: 0;
}

.jvectormap-legend-cnt-v
{
    right: 0;
    top: 0;
}

.jvectormap-legend
{
    background: black;
    border-radius: 3px;
    color: white;
}

.jvectormap-legend-cnt-h .jvectormap-legend
{
    float: left;
    margin: 0 10px 10px 0;
    padding: 3px 3px 1px 3px;
}

    .jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick
    {
        float: left;
    }

.jvectormap-legend-cnt-v .jvectormap-legend
{
    margin: 10px 10px 0 0;
    padding: 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick
{
    width: 40px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample
{
    height: 15px;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-sample
{
    display: inline-block;
    height: 20px;
    vertical-align: middle;
    width: 20px;
}

.jvectormap-legend-tick-text
{
    font-size: 12px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-text
{
    text-align: center;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-text
{
    display: inline-block;
    line-height: 20px;
    padding-left: 3px;
    vertical-align: middle;
}
